<template>
	<v-card
		elevation="0"
		class="ma-0 pa-0"
	>
		<v-card-text class="pl-0 py-4">
			<v-row justify="space-between">
				<v-col cols="auto" class="py-0">
					<v-alert
						dense
						outlined
						border="left"
						:value="errorOn && !dialog"
						:type="errorType"
						class="ma-0 py-1"
					>
						{{ errorMessage }}
						<font v-if="!isLoaded" @click="getEmployment" style="cursor:pointer;"><strong>, click here</strong> to reload</font>
					</v-alert>
				</v-col>
				
				<v-col cols="auto" class="text-right py-0 mb-1">
					<template>
						<v-tooltip bottom nudge-bottom="-15">
							<template v-slot:activator="{ on: onToolTip }">
								<v-icon
									size="30"
									:color="hoverNew? 'blue darken-1' : 'blue darken-2'"
									v-on="onToolTip"
									@mouseover="hoverNew = !hoverNew"
									@mouseleave="hoverNew = !hoverNew"
									@click="newItem"
								>mdi-plus-circle</v-icon>
							</template>
							<small>New</small>
						</v-tooltip>
					</template>
					
					<template>
						<v-tooltip bottom nudge-bottom="-15">
							<template v-slot:activator="{ on: onToolTip }">
								<v-icon
									size="30"
									:color="hoverRefresh? 'blue darken-1' : 'blue darken-2'"
									v-on="onToolTip"
									@mouseover="hoverRefresh = !hoverRefresh"
									@mouseleave="hoverRefresh = !hoverRefresh"
									@click="getEmployment"
								>mdi-refresh-circle</v-icon>
							</template>
							<small>Refresh</small>
						</v-tooltip>
					</template>
				</v-col>
			</v-row>
		</v-card-text>
		
		<v-card-text class="pa-0 ma-0 pr-1">
			<v-data-table
				:dense="$vuetify.breakpoint.lgAndDown"
				fixed-header
				:headers="headers"
				:height="tableHeight"
				:items="employment"
				:loading="dataTableLoading"
				loading-text="Loading... Please wait"
				no-data-text="No records found."
				:options.sync="tableOptions"
				:page="tableOptions.page"
				:server-items-length="tableTotal"
				ref="dataTableEmployment"
				@update:items-per-page="tableResize; getEmployment();"
				@update:page="getEmployment"
				@update:sort-desc="getEmployment"
			>
			
				/* Header */
				<template v-slot:header.H_VSSLNAME="{ header }">
					{{ header.text }}<br/>AGENCY NAME
				</template>
			
				<template v-slot:header.H_VSSLTYPE="{ header }">
					{{ header.text }}<br/>MM/CE NATIONALITY
				</template>
				
				<template v-slot:header.H_RANKCODE="{ header }">
					{{ header.text }}<br/>SALARY
				</template>
				
				<template v-slot:header.GRT_DWT="{ header }">
					{{ header.text }}<br/>KW
				</template>
				
				<template v-slot:header.ENGINE_TYPE="{ header }">
					{{ header.text }}<br/>ENGINE BHP
				</template>
				
				<template v-slot:header.H_SIGNONDATE="{ header }">
					{{ header.text }}<br/>SIGNOFF
				</template>
				
				<template v-slot:header.DURATION="{ header }">
					{{ header.text }}<br/>TERMINATION CAUSE
				</template>
				/* Header */
				
				/* Content */
				<template v-slot:item.H_VSSLNAME="{ item }" >
					<div style="min-height:20px;">{{ item.H_VSSLNAME }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.AGENCY }}</div>
				</template>
				
				<template v-slot:item.H_VSSLTYPE="{ item }" >
					<div style="min-height:20px;">{{ item.H_VSSLTYPE }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.NATIONALITY }}</div>
				</template>
				
				<template v-slot:item.H_RANKCODE="{ item }" >
					<div style="min-height:20px;">{{ item.H_RANKCODE }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">
						{{ (item.SALARY > 0 && item.CURRENCY == 'PHP')? '&#8369;' : (item.SALARY > 0 && item.CURRENCY == 'USD')? '&#36;' : '' }}
						{{ (item.SALARY > 0 )? item.SALARY : '' }}
					</div>
				</template>
				
				<template v-slot:item.SALARY="{ item }" >
					<div v-if="$vuetify.breakpoint.xsOnly" style="min-height:20px;">
						{{ (item.SALARY > 0 && item.CURRENCY == 'PHP')? '&#8369;' : (item.SALARY > 0 && item.CURRENCY == 'USD')? '&#36;' : '' }}
						{{ (item.SALARY > 0 )? item.SALARY : '' }}
					</div>
				</template>
				
				<template v-slot:item.KW="{ item }" >
					<div v-if="$vuetify.breakpoint.xsOnly" style="min-height:20px;">{{ (item.KW > 0 )? item.KW : '' }}</div>
				</template>
				
				<template v-slot:item.GRT_DWT="{ item }" >
					<div style="min-height:20px;">{{ item.GRT_DWT }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ (item.KW > 0 )? item.KW : '' }}</div>
				</template>
				
				<template v-slot:item.ENGINE_TYPE="{ item }" >
					<div style="min-height:20px;">{{ item.ENGINE_TYPE }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.ENGINE_BHP }}</div>
				</template>
				
				<template v-slot:item.H_SIGNONDATE="{ item }" >
					<div style="min-height:20px;">{{ item.H_SIGNONDATE }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.H_SIGNOFDATE }}</div>
				</template>
				
				<template v-slot:item.DURATION="{ item }" >
					<div style="min-height:20px;">{{ OBDuration(item.H_SIGNONDATE, item.H_SIGNOFDATE) }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.TERMDESC }}</div>
				</template>
				
				<template v-slot:item.action="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onToolTip }">
							<v-icon small v-on="onToolTip" color="success" @click="editItem(item)">mdi-pencil</v-icon>
						</template>
						<small>Edit</small>
					</v-tooltip>
					
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onToolTip }">
							<v-icon small v-on="onToolTip" color="error" @click="dialogDelete = true;deleteIndex = item">mdi-delete</v-icon>
						</template>
						<small>Delete</small>
					</v-tooltip>
				</template>
				/* Content */
			</v-data-table>
			
			<v-dialog
				v-model="dialog"
				:fullscreen="$vuetify.breakpoint.xsOnly && !dialogLoading"
				max-width="650"
				:persistent="dialogLoading"
				scrollable
				@click:outside="resetFormValidation();"
				@keydown.enter="saveItem"
			>
				<v-card v-if="!dialogLoading">
				
					<v-card-title class="headline primary white--text" primary-title>
						{{ dialogTitle }}
						
						<v-spacer></v-spacer>
							
						<v-btn icon
							@click="$refs.form.resetValidation(); dialog = false;"
							class="white--text"
						>
							<v-icon small dark>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					
					<v-card-text>
						<v-form
							ref="form"
							v-model="validForm"
							lazy-validation
						>
							<v-row dense no-gutters>
								<v-col cols="12">
									<v-alert
										dense
										outlined
										border="left"
										:value="errorOn && dialog"
										:type="errorType"
										class="mt-2 py-1"
									>
										{{ errorMessage }}
									</v-alert>
								</v-col>
								
								<v-col cols="12">
									<v-autocomplete
										label="Name of Agency*"
										v-model="editedItem.AGENCY"
										v-if="!newAgency"
										clearable
										validate-on-blur
										:items="optionAgency"
										item-text="AGENCY_NAME"
										item-value="AGENCY"
										:rules="rulesAgency"
										:search-input.sync="searchAgency"
										:loading="searchingAgency"
										:no-data-text= "noDataTextAgency"
										:allow-overflow=false
										@focus="focusAgency = true;"
										@blur="focusAgency = false;"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newAgency = !newAgency; oldAgency = editedItem.AGENCY; editedItem.AGENCY = '';">mdi-plus</v-icon>
												</template>
												<small>Others</small>
											</v-tooltip>
										</template>
									</v-autocomplete>
									
									<v-text-field
										label="Name of Agency*"
										v-model="editedItem.AGENCY_NAME"
										validate-on-blur
										v-if="newAgency"
										hint="Please specify here"
										persistent-hint
										:rules="rulesAgencyNew"
										@blur="editedItem.AGENCY_NAME = trimBlur(editedItem.AGENCY_NAME)"
										class="uppercase-input"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newAgency = !newAgency; editedItem.AGENCY_NAME = oldAgency">mdi-format-list-bulleted</v-icon>
												</template>
												<small>Select</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
								
								<v-col cols="12" sm="6" md="6" lg="6" xl="6">
									<v-text-field
										label="Vessel Name*"
										v-model="editedItem.H_VSSLNAME"
										:rules="rulesVesselName"
										validate-on-blur
										@blur="editedItem.H_VSSLNAME = trimBlur(editedItem.H_VSSLNAME)"
										class="uppercase-input"
									></v-text-field>
								</v-col>
								
								<v-col cols="12" sm="6" md="6" lg="6" xl="6">
									<v-select
										label="Rank*"
										v-if="!newRank"
										v-model="editedItem.H_RANKCODE"
										:rules="rulesRank"
										:items="optionRank"
										item-text="RANKDESC"
										item-value="RANK"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newRank = !newRank; oldRank = editedItem.H_RANKCODE; editedItem.H_RANKCODE = '';">mdi-plus</v-icon>
												</template>
												<small>Others</small>
											</v-tooltip>
										</template>
									</v-select>
								
									<v-text-field
										label="Rank*"
										v-model="editedItem.RANKDESC"
										validate-on-blur
										v-if="newRank"
										hint="Specify here"
										persistent-hint
										:rules="rulesRankNew"
										@blur="editedItem.RANKDESC = trimBlur(editedItem.RANKDESC)"
										class="uppercase-input"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newRank = !newRank; editedItem.RANKDESC = oldRank;">mdi-format-list-bulleted</v-icon>
												</template>
												<small>Select</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
								
								<v-col cols="12" sm="6" md="6" lg="6" xl="6">
									<v-select
										label="Vessel Type*"
										v-model="editedItem.H_VSSLTYPE"
										v-if="!newVesselType"
										:rules="rulesVesselType"
										:items="optionVesselType"
										item-text="VSSLTYPE"
										item-value="VSSLTYPE"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newVesselType = !newVesselType; oldVesselType = editedItem.H_VSSLTYPE; editedItem.H_VSSLTYPE = '';">mdi-plus</v-icon>
												</template>
												<small>Others</small>
											</v-tooltip>
										</template>
									</v-select>
								
									<v-text-field
										label="Vessel Type*"
										v-model="editedItem.H_VSSLTYPE"
										validate-on-blur
										v-if="newVesselType"
										hint="Specify here"
										persistent-hint
										:rules="rulesVesselType"
										@blur="editedItem.H_VSSLTYPE = trimBlur(editedItem.H_VSSLTYPE)"
										class="uppercase-input"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newVesselType = !newVesselType; editedItem.H_VSSLTYPE = oldVesselType;">mdi-format-list-bulleted</v-icon>
												</template>
												<small>Select</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
								
								<v-col cols="12" sm="6" md="6" lg="6" xl="6">
									<v-select
										label="Nationality of MM/CE*"
										v-model="editedItem.NATIONALITY"
										v-if="!newNationality"
										:rules="rulesNationality"
										:items="optionNationality"
										item-text="NATIONALITY"
										item-value="NATIONALITY"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newNationality = !newNationality; oldNationality = editedItem.NATIONALITY; editedItem.NATIONALITY = '';">mdi-plus</v-icon>
												</template>
												<small>Others</small>
											</v-tooltip>
										</template>
									</v-select>
								
									<v-text-field
										label="Nationality of MM/CE*"
										v-model="editedItem.NATIONALITY"
										validate-on-blur
										v-if="newNationality"
										hint="Specify here"
										persistent-hint
										:rules="rulesNationality"
										@blur="editedItem.NATIONALITY = trimBlur(editedItem.NATIONALITY)"
										class="uppercase-input"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newNationality = !newNationality; editedItem.NATIONALITY = oldNationality;">mdi-format-list-bulleted</v-icon>
												</template>
												<small>Select</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
								
								<v-col cols="12" sm="6" md="6" lg="6" xl="6">
									<v-text-field
										label="GRT/DWT"
										v-model="editedItem.GRT_DWT"
									></v-text-field>
								</v-col>
								
								<v-col cols="12" sm="6" md="6" lg="6" xl="6">
									<v-text-field
										label="KW"
										v-model="editedItem.KW"
									></v-text-field>
								</v-col>
								
								<v-col cols="12" sm="6" md="6" lg="6" xl="6">
									<v-text-field
										label="Engine Type"
										v-model="editedItem.ENGINE_TYPE"
									></v-text-field>
								</v-col>
								
								<v-col cols="12" sm="6" md="6" lg="6" xl="6">
									<v-text-field
										label="ENG / BHP"
										v-model="editedItem.ENG_BHP"
									></v-text-field>
								</v-col>
								
								<v-col cols="12" sm="6" md="6" lg="6" xl="6">
									<v-text-field
										label="Signon Date*"
										v-model="editedItem.H_SIGNONDATE"
										hint="MM/DD/YYYY"
										persistent-hint
										:rules="rulesSignOn"
										:type="dateTypeSignOn"
										@focus="dateTypeSignOn = 'date'"
										@blur="dateTypeSignOn = (isValidDate(editedItem.H_SIGNONDATE))? 'date' : 'text'"
									></v-text-field>
								</v-col>
								
								<v-col cols="12" sm="6" md="6" lg="6" xl="6">
									<v-text-field
										label="Signoff Date*"
										v-model="editedItem.H_SIGNOFDATE"
										hint="MM/DD/YYYY"
										persistent-hint
										:rules="rulesSignOff"
										:type="dateTypeSignOff"
										@focus="dateTypeSignOff = 'date'"
										@blur="dateTypeSignOff = (isValidDate(editedItem.H_SIGNOFDATE))? 'date' : 'text'"
									></v-text-field>
								</v-col>
								
								<v-col cols="12" sm="6" md="6" lg="6" xl="6">
									<v-text-field
										label="Salary"
										v-model="editedItem.SALARY"
										v-mask="maskSalary"
										:append-outer-icon="salaryUSD ? 'mdi-currency-usd' : 'mdi-currency-php'"
										@click:append-outer="salaryUSD = !salaryUSD"
										@blur="editedItem.SALARY = editedItem.SALARY.replace(/^0+/, '')"
										class="mr-1"
									></v-text-field>
								</v-col>
								
								<v-col cols="12" sm="6" md="6" lg="6" xl="6">
									<v-select
										label="Termination Cause*"
										v-model="editedItem.TERMINATIONCODE"
										v-if="!newTerminationCause"
										:rules="rulesTerminationCause"
										validate-on-blur
										:items="optionTermination"
										item-text="TERMDESC"
										item-value="ID"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newTerminationCause = !newTerminationCause; oldTerminationCause = editedItem.TERMINATIONCODE; editedItem.TERMINATIONCODE = '';">mdi-plus</v-icon>
												</template>
												<small>Others</small>
											</v-tooltip>
										</template>
									</v-select>
								
									<v-text-field
										label="Termination Cause*"
										v-model="editedItem.TERMDESC"
										validate-on-blur
										v-if="newTerminationCause"
										hint="Specify here"
										persistent-hint
										:rules="rulesTerminationCauseNew"
										@blur="editedItem.TERMDESC = trimBlur(editedItem.TERMDESC)"
										class="uppercase-input"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newTerminationCause = !newTerminationCause; editedItem.TERMDESC = oldTerminationCause;">mdi-format-list-bulleted</v-icon>
												</template>
												<small>Select</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
							</v-row>
						</v-form>
						
					</v-card-text>
					
					<v-card-actions>
						<small class="pl-4 font-weight-light">Note: Field with asterisk(*) is required.</small>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="$refs.form.resetValidation(); dialog = false;">Cancel</v-btn>
						<v-btn color="blue darken-1" text @click="saveItem">Save</v-btn>
					</v-card-actions>
				</v-card>
				
				<v-card
					color="primary"
					dark
					v-if="dialogLoading"
				>
					<v-card-text class="pt-2">
						Saving...
						<v-progress-linear
							indeterminate
							color="white"
							class="mb-0"
						></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
			
			<v-dialog
				v-model="dialogDelete"
				max-width="355"
				:persistent="dialogLoading"
			>
				<v-card
					color="primary"
					dark
					v-if="dialogLoading"
				>
					<v-card-text class="pt-2">
						Deleting...
						<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
				
				<v-card v-else-if="!dialogLoading">
					<v-card-title class="subtitle-1 warning white--text">CONFIRM DELETE</v-card-title>
					
					<v-card-text class="subtitle-1 font-weight-medium pt-5">Are you sure you want to delete this item?</v-card-text>
					
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="dialogDelete = false">NO</v-btn>
						<v-btn color="blue darken-1" text @click="deleteItem()">YES</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-card-text>
	</v-card>	
	
</template>

<script>
import { CLEAR_ALERT } from '@/store/actions/user';

import {mask} from 'vue-the-mask';
import { mapGetters } from 'vuex';

var timeoutData			= null;
var timeoutLoading		= null;
var timeoutDropdown		= null;
var timeoutTableResize	= null;
var timeoutDialogClose	= null;
var timeoutWindowResize	= null;
var timeoutAgency		= null;

export default {
	directives: {mask},
	data () {
		return {
			tableTotal			: 0,
			tableOptions		: {	page: 1, itemsPerPage: 10, sortBy: ['H_SIGNONDATE'], sortDesc: [true] },
			
			salaryUSD			: true,
			
			dateTypeSignOn		: 'text',
			dateTypeSignOff		: 'text',
			
			hoverNew			: false,
			hoverRefresh		: false,
			
			newAgency			: false,
			newRank				: false,
			newVesselType		: false,
			newNationality		: false,
			newTerminationCause	: false,
			
			oldAgency			: '',
			oldRank				: '',
			oldVesselType		: '',
			oldNationality		: '',
			oldTerminationCause	: '',
			
			optionAgency		: [],
			searchAgency		: null,
			searchingAgency		: false,
			noDataTextAgency	: 'Start typing to search',
			focusAgency			: false,
			
			yearNow				: '',
			yearMin				: '',
			
			isLoaded			: false,
			
			dialog				: false,
			validForm			: true,
			dialogDelete		: false,
			dialogLoading		: false,
			dataTableLoading	: false,
			isLoadedOptions		: false,
			
			tableHeight			: 'undefined',
			
			employment			: [],
			optionVesselType	: [],
			optionRank			: [],
			optionTermination	: [],
			optionNationality	: [],
			
			maskSalary			: '#######',
			
			deleteIndex			: [],
			editedIndex			: -1,
			editedItem: {
				ID				: '',
				H_VSSLNAME		: '',
				AGENCY			: '',
				AGENCY_NAME		: '',
				H_VSSLTYPE		: '',
				GRT_DWT			: '',
				KW				: '',
				ENGINE_TYPE		: '',
				TERMDESC		: '',
				TERMINATIONCODE	: '',
				CURRENCY		: '',
				ENG_BHP			: '',
				NATIONALITY		: '',
				H_RANKCODE		: '',
				RANKDESC		: '',
				SALARY			: '',
				H_SIGNONDATE	: '',
				H_SIGNOFDATE	: '',
				DURATION		: '',
			},
			defaultItem: {
				ID				: '',
				H_VSSLNAME		: '',
				AGENCY			: '',
				AGENCY_NAME		: '',
				H_VSSLTYPE		: '',
				GRT_DWT			: '',
				KW				: '',
				ENGINE_TYPE		: '',
				TERMDESC		: '',
				TERMINATIONCODE	: '',
				CURRENCY		: '',
				ENG_BHP			: '',
				NATIONALITY		: '',
				H_RANKCODE		: '',
				RANKDESC		: '',
				SALARY			: '',
				H_SIGNONDATE	: '',
				H_SIGNOFDATE	: '',
				DURATION		: '',
			},
			headers: [
				{ text: 'VESSEL NAME',		value: 'H_VSSLNAME',	sortable: false, align: 'start' },
				{ text: 'AGENCY NAME',		value: 'AGENCY',		sortable: false, align: ' d-none' },
				{ text: 'VESSEL TYPE',		value: 'H_VSSLTYPE',	sortable: false, align: 'start' },
				{ text: 'MM/CE NATIONALITY',value: 'NATIONALITY',	sortable: false, align: ' d-none' },
				{ text: 'RANK',				value: 'H_RANKCODE',	sortable: false },
				{ text: 'SALARY',			value: 'SALARY',		sortable: false, align: ' d-none'	},
				{ text: 'GRT/DWT',			value: 'GRT_DWT',		sortable: false, align: 'end' },
				{ text: 'KW',				value: 'KW',			sortable: false, align: ' d-none' },
				{ text: 'ENGINE TYPE',		value: 'ENGINE_TYPE',	sortable: false, align: 'center' },
				{ text: 'ENGINE BHP',		value: 'ENG_BHP',		sortable: false, align: ' d-none' },
				{ text: 'SIGNON',			value: 'H_SIGNONDATE',	align: 'center' },
				{ text: 'SIGNOFF',			value: 'H_SIGNOFDATE',	sortable: false, align: ' d-none' },
				{ text: 'ONBOARD DURATION',	value: 'DURATION',		sortable: false, align: 'center' },
				{ text: 'ACTIONS',			value: 'action',		sortable: false, align: 'center' },
			],
			rulesAgency: [
				v => !!v || 'Agency is required',
			],
			rulesAgencyNew: [
				v => !!v || 'Agency is required',
				v => (!!v && this.stringLength(v) >= 2) || 'Must be atleast 2 characters',
				v => (!!v && this.stringLength(v) <= 100) || 'Must be less than 100 characters',
			],
			rulesVesselName: [
				v => !!v || 'Vessel name is required',
				v => (!!v && this.stringLength(v) >= 5) || 'Must be atleast 5 characters',
				v => (!!v && this.stringLength(v) <= 50) || 'Must be less than 50 characters',
			],
			rulesRank: [
				v => !!v || 'Rank is required',
				v => (!!v && this.stringLength(v) >= 2) || 'Must be atleast 2 characters',
				v => (!!v && this.stringLength(v) <= 20) || 'Must be less than 20 characters',
			],
			rulesRankNew: [
				v => !!v || 'Rank is required',
				v => (!!v && this.stringLength(v) >= 2) || 'Must be atleast 2 characters',
				v => (!!v && this.stringLength(v) <= 50) || 'Must be less than 50 characters',
			],
			rulesVesselType: [
				v => !!v || 'Vessel Type is required',
				v => (!!v && this.stringLength(v) >= 3) || 'Must be atleast 3 characters',
				v => (!!v && this.stringLength(v) <= 50) || 'Must be less than 50 characters',
			],
			rulesNationality: [
				v => !!v || 'MM/CE Nationality is required',
				v => (!!v && this.stringLength(v) >= 3) || 'Must be atleast 3 characters',
				v => (!!v && this.stringLength(v) <= 100) || 'Must be less than 100 characters',
			],
			rulesSignOn: [
				v => !!v || 'Signon date is required',
				(v) => this.compareDate(v, this.editedItem.H_SIGNOFDATE, '=') || 'Must be equal or less than signoff date',
			],
			rulesSignOff: [
				v => !!v || 'Signoff date is required',
				(v) => this.compareDate(this.editedItem.H_SIGNONDATE, v, '=') || 'Must be equal or greater than signon date',
			],
			rulesTerminationCause: [
				v => !!v || 'Termination Cause is required',
			],
			rulesTerminationCauseNew: [
				v => !!v || 'Termination Cause is required',
				v => (!!v && this.stringLength(v) >= 5) || 'Must be atleast 5 characters',
				v => (!!v && this.stringLength(v) <= 100) || 'Must be less than 100 characters',
			],
		}
	},
	methods: {
		getEmployment () {
			if(this.dataTableLoading && typeof source !== 'undefined')source.cancel();
			
			const CancelToken = this.$http.CancelToken;
			let source = CancelToken.source();

			this.$store.dispatch(CLEAR_ALERT);
			this.dataTableLoading = true;
			this.isLoaded = false;
			
			clearTimeout(timeoutData);
				
			this.$http.get('employment', { params:{ options: this.tableOptions } }, { cancelToken: source.token })
			.then((resp) => {
				this.employment = resp.data.data;
				this.tableTotal = resp.data.rows;
				this.isLoaded = true;
				this.dropdown();
			})
			.catch(() => {
				timeoutData = setTimeout(() => {
					this.getEmployment();
				}, 60000);
			})
			.finally(() => {
				this.dataTableLoading = false;
				this.tableResize();
			});
		},
		newItem: function(){
			this.$store.dispatch(CLEAR_ALERT);
			this.dialog = true;
			
			setTimeout(() => {
				if(typeof this.$refs.form !== 'undefined')this.$refs.form.resetValidation();
			}, 50);
		},
		editItem: function(item){
			if(this.isValidDate(item.H_SIGNONDATE)){
				this.dateTypeSignOn = 'date';
			}else{
				item.H_SIGNONDATE = '';
				this.dateTypeSignOn = 'text';
			}
			
			if(this.isValidDate(item.H_SIGNOFDATE)){
				this.dateTypeSignOff = 'date';
			}else{
				item.H_SIGNOFDATE = '';
				this.dateTypeSignOff = 'text';
			}
			
			if(item.AGENCY != '')this.optionAgency.push( {'AGENCY': item.AGENCY, 'AGENCY_NAME': item.AGENCY_NAME} );
			if(item.H_RANKCODE != '')this.optionRank.push( {'RANK': item.H_RANKCODE, 'RANKDESC': item.RANKDESC} );
			if(item.H_VSSLTYPE != '')this.optionVesselType.push( {'VSSLTYPE': item.H_VSSLTYPE} );
			if(item.NATIONALITY != '')this.optionNationality.push( {'NATIONALITY': item.NATIONALITY} );
			if(item.TERMINATIONCODE != '')this.optionTermination.push( {'ID': item.TERMINATIONCODE, 'TERMDESC': item.TERMDESC} );
			
			this.salaryUSD = (item.CURRENCY == 'PHP')? false : true;
			
			this.editedIndex = this.employment.indexOf(item);
			this.editedItem = Object.assign({}, item);
			
			this.$store.dispatch(CLEAR_ALERT);
			this.dialog = true;
		},
		deleteItem: function(){
			this.$store.dispatch(CLEAR_ALERT);
			this.dialogLoading = true;
			this.$http.delete('employment', {
				params: {
					ID: this.deleteIndex.ID
				}
			}).then(() => {
				const index = this.employment.indexOf(this.deleteIndex);
				this.employment.splice(index, 1);
				this.tableTotal--;
				this.tableResize();
			})
			.finally(() => {
				this.dialogDelete = false;
				clearTimeout(timeoutLoading);
				timeoutLoading = setTimeout(() => {
					this.dialogLoading = false;
				}, 300);
			});
		},
		saveItem: function(){
			if(this.$refs.form.validate()){
				this.$store.dispatch(CLEAR_ALERT);
				this.dialogLoading = true;
				
			//	if(this.newTerminationCause){
			//		this.editedItem.TERMDESC = this.editedItem.TERMINATIONCODE;
			//	}else{
			//		this.editedItem.TERMDESC = this.optionTermination.find(({ ID }) => ID === this.editedItem.TERMINATIONCODE).TERMDESC;
			//	}
				
				this.editedItem.CURRENCY = (this.salaryUSD)? 'USD' : 'PHP';
				
				if(this.editedIndex > -1){//Edit Item
					this.$http.put('employment', {
						forms				: this.editedItem,
						newAgency			: this.newAgency,
						newRank				: this.newRank,
						newVesselType		: this.newVesselType,
						newNationality		: this.newNationality,
						newTerminationCause	: this.newTerminationCause
					}).then((resp) => {
						
						if(this.newAgency)this.editedItem.AGENCY = resp.data.ACODE;
						if(this.newRank)this.editedItem.H_RANKCODE = resp.data.RCODE;
						if(this.newTerminationCause)this.editedItem.TERMINATIONCODE = resp.data.TID;
						
						Object.assign(this.employment[this.editedIndex], this.editedItem);
						this.tableResize();
						this.dialog = false;
					})
					.catch(() => {
						this.dialog = true;
					})
					.finally(() => {
						clearTimeout(timeoutLoading);
						timeoutLoading = setTimeout(() => {
							this.dialogLoading = false;
						}, 300)
					});
				}else{//New Item
					this.$http.post('employment', {
						forms				: this.editedItem,
						newAgency			: this.newAgency,
						newRank				: this.newRank,
						newVesselType		: this.newVesselType,
						newNationality		: this.newNationality,
						newTerminationCause	: this.newTerminationCause
					}).then((resp) => {
						this.editedItem.ID = resp.data.ID;
						if(this.newAgency)this.editedItem.AGENCY = resp.data.ACODE;
						if(this.newRank)this.editedItem.H_RANKCODE = resp.data.RCODE;
						if(this.newTerminationCause)this.editedItem.TERMINATIONCODE = resp.data.TID;
						
						this.tableTotal++;
						this.employment.unshift(this.editedItem);
						
						this.tableResize();
						this.dialog = false;
					})
					.catch(() => {
						this.dialog = true;
					})
					.finally(() => {
						clearTimeout(timeoutLoading);
						timeoutLoading = setTimeout(() => {
							this.dialogLoading = false;
						}, 300)
					});
				}
			}
		},
		tableResize(){
			clearTimeout(timeoutTableResize);
			timeoutTableResize = setTimeout(() => {
				if(typeof this.$refs.dataTableEmployment !== 'undefined'){
					const windowWidth = this.$refs.dataTableEmployment.$parent.$el.clientWidth;
					const tableWidth = this.$refs.dataTableEmployment.$el.children[0].children[0].clientWidth;
					const scrollX = ( tableWidth > windowWidth)? 20 : 0;
					
					const tbodyHeight= this.$refs.dataTableEmployment.$el.children[0].children[0].clientHeight;
					this.tableHeight = ( (this.userFormHeight - (40 + 65) ) > tbodyHeight )? tbodyHeight : this.userFormHeight - (40 + 65);
					this.tableHeight += scrollX;
				}
			}, 50);
		},
		dropdown(){//populate dropdown
			if(!this.isLoadedOptions){
				this.$http.get('dropdown/employmentDropdowns').then((resp) => {
					this.optionVesselType = resp.data.vesselType;
					this.optionRank = resp.data.rank;
					this.optionTermination = resp.data.termination;
					this.optionNationality = resp.data.mmNationality;
				})
				.then(() => {
					this.isLoadedOptions = true;
				})
				.catch(() => {//retry
					clearTimeout(timeoutDropdown);
					timeoutDropdown = setTimeout(() => {
						this.dropdown();
					}, 10000)
				});
			}
		},
		resetFormValidation(){
			if(!this.dialogLoading)this.$refs.form.resetValidation();
		},
	/*	OBDuration(str){
			var arr = str.split(",");
			str = "";
			
			if( arr[0] > 0 ){
				str = ( arr[0] == 1 )? arr[0]+" Year" : arr[0]+" Years";
			}
			
			if( arr[1] > 0 ){
				if( str != "" )str = str+", ";
				str += ( arr[1] == 1 )? arr[1]+" Month" : arr[1]+" Months";
			}
			
			if( arr[2] > 0 ){
				if( str != "" )str = str+", ";
				str += ( arr[2] == 1 )? arr[2]+" Day" : arr[2]+" Days";
			}
			
			str = str.replace(/,([^,]*)$/, ' &' + '$1');
			
			return str;
		},
	*/	OBDuration(d1, d2){
			
			var days = 0;
			var months = 0;
			var years = 0;
			
			//YYYY-MM-DD
			var arr1 = d1.split("-");
			var arr2 = d2.split("-");
			
			/*First get the number of full years*/
			var year1 = arr1[0] - 0;
			var year2 = arr2[0] - 0;

			var month1 = arr1[1] - 0;
			var month2 = arr2[1] - 0;

			var day1 = arr1[2] - 0;
			var day2 = arr2[2] - 0;

			/*Set initial values bearing in mind the months or days may be negative*/
			years = year2 - year1;
			months = month2 - month1;
			days = day2 - day1;

			/*Now we deal with the negatives*/

			/*First if the day difference is negative eg dt2 = 13 oct, dt1 = 25 sept*/
			if (days < 0){
				/*Use temporary dates to get the number of days remaining in the month*/
				var dtmp1 = new Date(year2, month2 - 1, 0);

				var numDays = dtmp1.getDate();
				months -= 1;
				days = (day1 > numDays)? day2 : day2 + (numDays - day1);
			}

			/*Now if the month difference is negative*/
			if (months < 0){
				months += 12;
				years -= 1;
			}

			var str = "";
			
			if( years > 0 ){
				str = ( years == 1 )? years+" Year" : years+" Years";
			}
			
			if( months > 0 ){
				if( str != "" )str = str+", ";
				str += ( months == 1 )? months+" Month" : months+" Months";
			}
			
			if( days > 0 ){
				if( str != "" )str = str+", ";
				str += ( days == 1 )? days+" Day" : days+" Days";
			}
			
			str = str.replace(/,([^,]*)$/, ' &' + '$1');
			
			return str;
		},
		compareDate(d1 , d2, opr){
			if( d1 == '' || d2 == '' || typeof d1 === 'undefined' || typeof d2 === 'undefined' ){
				return true;
			}else{
				var x = new Date(d1);
				var y = new Date(d2);
				return ( opr == '=' )? y >= x : y > x;
			}
		},
		stringLength(text){
			return (typeof text === 'undefined' || text === null)? 0 : text.length;
		},
		trimBlur(text){
			return (typeof text === 'undefined')? '' : text.replace('  ', ' ').toUpperCase().trimStart().trim();
		},
		closeDialog () {
			this.dialog = false
			clearTimeout(timeoutDialogClose);
			timeoutDialogClose = setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
				
				this.salaryUSD = true;

				this.newAgency = this.newRank = this.newVesselType = false;
				this.newNationality = this.newTerminationCause = false;
				
				this.dateTypeSignOn = this.dateTypeSignOff = 'text';
			}, 50);
		},
		isValidDate(dateString) {
			if(dateString == null)return false;
			var regEx = /^\d{4}-\d{2}-\d{2}$/;
			if(!dateString.match(regEx)) return false;  // Invalid format
			var d = new Date(dateString);
			var dNum = d.getTime();
			if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
			return d.toISOString().slice(0,10) === dateString;
		},
	},
	computed: {
		...mapGetters(['userFormHeight', 'serverDate', 'windowSize', 'errorOn', 'errorType', 'errorMessage']),
		dialogTitle () {
			return this.editedIndex === -1 ? 'New Employment' : 'Edit Employment'
		},
	},
	watch: {
		dialog (val) {
			val || this.closeDialog()
		},
		searchAgency(val){
			if(!this.focusAgency) return
			
			clearTimeout(timeoutAgency);
			
			timeoutAgency = setTimeout(() => {
				this.searchingAgency = true;
				this.noDataTextAgency = "Searching..."
				this.$http.get('autocomplete/manningAgency', {
					params: {
						search: val
					}
				})
				.then((res) => {
					this.optionAgency = res.data;
				}).finally(() => {
					this.searchingAgency = false;
					this.noDataTextAgency = (this.optionAgency.length == 0)? "No data available" : "Searching...";
				});
			}, 500);
		},
		windowSize(newVal, oldVal){
			if( newVal != oldVal ){
				clearTimeout(timeoutWindowResize);
				timeoutWindowResize = setTimeout(() => {
					this.tableResize();
				}, 600)
			}
		},
	},
	created (){
		this.getEmployment();
	},
	mounted (){
		this.yearNow = this.serverDate.substr(0, 4);
		this.yearMin = this.serverDate.substr(0, 4) - 60;
	},
	beforeDestroy(){
		clearTimeout(timeoutData);
		clearTimeout(timeoutLoading);
		clearTimeout(timeoutDropdown);
		clearTimeout(timeoutTableResize);
		clearTimeout(timeoutWindowResize);
		clearTimeout(timeoutDialogClose);
		clearTimeout(timeoutAgency);
	},
}
</script>

<style scoped>
	.uppercase-input >>> input {
		text-transform: uppercase
    }
</style>